import React, { useState, useEffect, useContext } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useForm } from "react-hook-form";
import axios from "../../utils/axios";
import {
  Card,
  CardHeader,
  FormControl,
  Grid,
  TextField,
  Button,
  Box,
  InputLabel,
  IconButton,
  Select,
  MenuItem,
  Tooltip
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomizedDialogs from "../ui/modal";
import { useGet, usePost, usePut, useDelete } from "../../hooks/useFetch";
import {
  FOREGROUND_COLOR_API,
  FOREGROUND_MATERIAL_API,
  GET,
  POST,
  PUT,
  DELETE,
} from "../../api/config";
import { useNavigate } from "react-router-dom";
import { hasPermission, ltrim } from "../utils/common";
import NotificationContext from "../../store/notification-context";
import ImagePicker from "../ui/image-picker";
import { appendImage } from "../../utils/helper";
import InfoIcon from '@mui/icons-material/Info';

const currencyArr = [
  {
    _id: "1",
    name: "$",
  },
];

function ForegroundColor() {
  const [cValue, setCValue] = useState("");
  const [fmValue, setFmValue] = useState("");
  const [showImage, setShowImage] = useState(false);
  const [image, setImage] = useState(null);
  const [foregroundColorlList, setforegroundColorlList] = useState([]);
  const [foregroundmaterialList, setForegroundmaterialList] = useState([]);
  const [action, setAction] = useState("view");
  const [rowData, setRowData] = useState(null);
  const [modal, setModal] = useState(false);
  const notificationCtx = useContext(NotificationContext);

  const slug = ltrim(window.location.pathname);
  const getPermissionData = hasPermission(slug);

  const { data: getData, refresh: refreshGetData } = useGet(
    FOREGROUND_COLOR_API[GET],
    {
      apiCall: "onload",
    }
  );
  const { data: foregroundMaterialData } = useGet(
    FOREGROUND_MATERIAL_API[GET],
    {
      apiCall: "onload",
    }
  );
  const { refresh: postData } = usePost(FOREGROUND_COLOR_API[POST]);
  const { refresh: putData } = usePut(FOREGROUND_COLOR_API[PUT]);
  const { refresh: delData } = useDelete(FOREGROUND_COLOR_API[DELETE]);

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [permissionData, setPermissionData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    setPermissionData(getPermissionData);
  }, []);

  useEffect(() => {
    //console.log(permissionData);
    if (!permissionData) {
      navigate("/dashboard", { replace: true });
    }
  }, [permissionData]);

  useEffect(() => {
    if (getData) {
      setforegroundColorlList(getData.data || []);
    }
  }, [getData]);

  useEffect(() => {
    if (foregroundMaterialData) {
      setForegroundmaterialList(foregroundMaterialData.data || []);
    }
  }, [foregroundMaterialData]);

  const columns = [
    { 
      field: "foregroundMaterialId", 
      headerName: "Name", 
      width: 150, 
      sortable: true,
      renderCell: (params) => {
        let selectedForegroundMaterial = foregroundmaterialList.find((itm) => itm._id == params.value);
        return <span>{selectedForegroundMaterial?selectedForegroundMaterial.name:""}</span>
      } 
    },
    { field: "name", headerName: "Color Name", width: 150, sortable: true },
    {
      field: "value",
      headerName: "Color Code",
      width: 150,
      sortable: true,
    },
    {
      field: "pattern",
      headerName: "Image",
      width: 150,
      sortable: true,
      renderCell: (params) => {
        //console.log("image : ", params.value);
        return params.value && <img
          src={appendImage(params.value)}
          style={{ transform: "scale(.8)" }}
        />;
      }
    },
    {
      field: "price",
      headerName: "Price",
      width: 150,
      sortable: true,
      renderCell: (params) => {
        return <span>{'$ ' + parseFloat(params.formattedValue).toFixed(2)}</span>
      }
    },
    {
      field: "currency",
      headerName: "Currency",
      width: 150,
      sortable: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 300,
      renderCell: (params) => (
        <Box
          sx={{
            m: 1,
            position: "relative",
          }}
        >
          {permissionData.update == true && (
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => editRowData(params)}
            >
              <EditIcon />
            </IconButton>
          )}
          {permissionData.delete == true && (
            <IconButton
              aria-label="delete"
              color="outlined"
              onClick={() => deleteRowData(params)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ),
    },
  ];

  const getModalTitle = () => {
    if (action === "edit") {
      return "Update Foreground Color";
    } else if (action === "add") {
      return "Add Foreground Color";
    } else {
      return "Delete Foreground Color";
    }
  };

  const closeModal = () => {
    setModal(false);
  };

  const currencyHandleChange = (event) => {
    setCValue(event.target.value);
  };

  const imageHandleChange = (event) => {
    console.log(event);
    setValue("value", "#");
  };

  const foregroundMaterialHandleChange = (event) => {
    //console.log("foregroundMaterialHandleChange : ", event.target.value);
    let val = event.target.value;
    
    let selectedMaterialItem = foregroundmaterialList.filter(itm => itm._id === val);
    //console.log("selectedMaterialItem : ", selectedMaterialItem);
    if(selectedMaterialItem.length > 0 && selectedMaterialItem[0].name === "Solid Color"){
      setShowImage(false);
    } else setShowImage(true);
    setValue("foregroundMaterial", val);
    setFmValue(val);
    
  };

  const editRowData = (params) => {
    const { name, value, price, currency, foregroundMaterialId, pattern } = params.row;
    console.log(params.row, "params.row");
    setRowData(params.row);
    setModal(true);
    setAction("edit");

    /* set field values */
    setValue("name", name);
    
    let selectedMaterialItem = foregroundmaterialList.filter(itm => itm._id === foregroundMaterialId);
    //console.log("selectedMaterialItem : ", selectedMaterialItem);
    if(selectedMaterialItem.length > 0 && selectedMaterialItem[0].name === "Solid Color"){
      setShowImage(false);
    } else setShowImage(true);

    if(value){
      //console.log("value : ", value);
      setValue("value", value);
      setValue("image", "");
      setImage("");
    }

    if(pattern){
      //console.log("pattern : ", pattern);
      setValue("value", "#");
      setValue("image", pattern);
      setImage(pattern);
    }

    setValue("price", price);
    setCValue(currency);
    setFmValue(foregroundMaterialId);
  };

  const onSubmit = (data) => {
    /* data = {
      ...data,
      currency: cValue,
      foregroundMaterialId: fmValue,
    }; */
    //console.log("data : ", data); 
    const formData = new FormData();
    let file;
    if (typeof data.image === "object" && data.image.length > 0) {
      file = data.image[0];
      formData.append("pattern", file);
    }
    formData.append("foregroundMaterialId", fmValue);
    formData.append("name", data.name);
    formData.append("price", data.price);
    formData.append("currency", cValue);

    delete data.image;
    if(!showImage){
      //delete data.pattern;
      formData.append("value", data.value);
    }

    console.log("formData : ", formData);  false;
    if (action === "add") {
      //postData({ data }, { refreshGetData, closeModal });
      axios({
        async: true,
        url: FOREGROUND_COLOR_API[POST],
        method: "POST",
        processData: false,
        contentType: false,
        mimeType: "multipart/form-data",
        data: formData,
      }).then((response) => {
        console.log(response, "response");
        setModal(false);
        refreshGetData();
        if(response.data.message){
          notificationCtx.showNotification({
            title: response.data.message,
            status: "success",
          });
        }
      }).catch(err => {
        //console.log(err.response.data.error);
        if(err.response.data.error){
          notificationCtx.showNotification({
            title: err.response.data,
            status: "error",
          });
        }
      });
    } else {
      /* data = {
        ...data,
        _id: rowData._id,
      }; */
      //putData({ data }, { refreshGetData, closeModal });
      formData.append("_id", rowData._id);
      axios({
        async: true,
        url: FOREGROUND_COLOR_API[PUT],
        method: "PUT",
        processData: false,
        contentType: false,
        mimeType: "multipart/form-data",
        data: formData,
      }).then((response) => {
        console.log(response, "response");
        setModal(false);
        refreshGetData();
        if(response.data.message){
          notificationCtx.showNotification({
            title: response.data.message,
            status: "success",
          });
        }
      }).catch(err => {
        //console.log(err.response.data.error);
        if(err.response.data){
          notificationCtx.showNotification({
            title: err.response.data.error,
            status: "error",
          });
        }
      });
    }
  };

  const deleteRowData = (params) => {
    setRowData(params.row);
    setModal(true);
    setAction("delete");
  };

  const deleteHandler = () => {
    const data = {
      _id: rowData._id,
    };

    delData({ data }, { refreshGetData, closeModal });
  };

  const changeColorCode = (e) => {
    setValue('value', e.target.value.toUpperCase());
    setValue("image", "");
    setImage("");
  };

  const changeColorName = (e) => {
    const inputValue = e.target.value;
    setValue('name', inputValue.charAt(0).toUpperCase() + inputValue.slice(1));
  };

  console.log("errors : ", errors);
  return (
    <Grid container spacing={6} style={{ marginTop: "0" }}>
      <Grid item xs={12} style={{paddingTop: "0"}}>
        <Card>
          <CardHeader
            title="Foreground Color Pricing"
            titleTypographyProps={{ variant: "h3", color: "primary.dark" }}
            action={
              permissionData.create == true && (
                <Button
                  variant="contained"
                  onClick={() => {
                    setModal(true);
                    setAction("add");
                    setImage("");
                    reset();

                    setFmValue(foregroundmaterialList[0]._id);
                  }}
                >
                  Add Foreground Color
                </Button>
              )
            }
          />
          <div style={{ width: "100%" }}>
            <DataGrid
              getRowId={(row) => row._id}
              rows={foregroundColorlList}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 20,
                  },
                },
              }}
              rowHeight={55}
              pageSizeOptions={[5]}
              onCellEditCommit={(params) => setRowId(params.id)}
            />
          </div>
        </Card>
      </Grid>
      {(permissionData.create == true ||
        permissionData.update == true ||
        permissionData.delete == true) && (
        <CustomizedDialogs
          showModal={modal}
          closeModal={closeModal}
          modalTitle={getModalTitle()}
        >
          {action === "delete" ? (
            <div>
              <p>Are you sure want to Delete?</p>
              <div
                className="align-right"
                style={{ display: "flex", justifyContent: "right" }}
              >
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  onClick={deleteHandler}
                >
                  Delete
                </Button>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="select-foregroundMaterial">
                      Select Foreground Material
                    </InputLabel>
                    <Select
                      labelId="select-foregroundMaterial"
                      id="select-foregroundMaterial"
                      value={fmValue}
                      label="foregroundMaterial"
                      onChange={foregroundMaterialHandleChange}
                      
                    >
                      {foregroundmaterialList.map((fg) => (
                        <MenuItem key={fg._id} value={fg._id}>
                          {fg.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Name"
                    placeholder="Enter Name"
                    inputProps={{
                      onChange: changeColorName
                    }}
                    {...register("name", { required: true })}
                  />
                  {errors.name && (
                    <span className="error-text">This field is required.</span>
                  )}
                </Grid>
                {!showImage?<Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="value"
                    placeholder="Please enter HEX code"
                      inputProps={{
                        onChange: changeColorCode,
                        maxLength: 7,
                        defaultValue: ""
                      }}
                    {...register("value", { required: (showImage?false:true) })}
                  />
                  {errors.value && (
                    <span className="error-text">This field is required.</span>
                  )}
                </Grid>:
                <Grid item xs={12}>
                  <Box display="inline-flex" alignItems="center">
                    <ImagePicker
                      {...{ register, errors }}
                      image={image}
                      value="image"
                      showError={false}
                      onChange={imageHandleChange}
                    />
                    <Tooltip title="Image Resolution - 1200×1406 Pixels" placement="right">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  {errors.image && (
                    <span className="error-text">This field is required.</span>
                  )}
                </Grid>}
                <Grid item xs={12}>
                  <TextField
                    type="number"
                    fullWidth
                    label="Price"
                    placeholder="Enter Price"
                    {...register("price", { required: true })}
                  />
                  {errors.price && (
                    <span className="error-text">This field is required.</span>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="select-role">Select Currency</InputLabel>
                    <Select
                      labelId="select-currency"
                      id="select-currency"
                      value={cValue}
                      label="currency"
                      onChange={currencyHandleChange}
                      {...register("currency", { required: true })}
                    >
                      {currencyArr.map((currency) => (
                        <MenuItem key={currency.name} value={currency.name}>
                          {currency.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.currency && (
                      <span className="error-text">This field is required.</span>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <div
                    className="align-right"
                    style={{ display: "flex", justifyContent: "right" }}
                  >
                    <Button type="submit" color="primary" variant="contained">
                      Submit
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          )}
        </CustomizedDialogs>
      )}
    </Grid>
  );
}

export default ForegroundColor;
