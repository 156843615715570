import React, { useState, useEffect, useContext } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useForm } from "react-hook-form";
import {
  Card,
  CardHeader,
  FormControl,
  Grid,
  TextField,
  Button,
  FormLabel,
  Box,
  InputLabel,
  IconButton,
  Select,
  MenuItem,
  Tooltip
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomizedDialogs from "../ui/modal";
import axios from "../../utils/axios";

import { useGet, usePost, useDelete } from "../../hooks/useFetch";
import { FONT_STYLE_API, GET, POST, PUT, DELETE } from "../../api/config";
import NotificationContext from "../../store/notification-context";
import { appendImage } from "../../utils/helper";
import ImagePicker from "../ui/image-picker";
import { useNavigate } from "react-router-dom";
import { hasPermission, ltrim } from "../utils/common";
import { insertLineBreaks } from "../../utils/helper";
import InfoIcon from '@mui/icons-material/Info';

const currencyArr = [
  {
    _id: "1",
    name: "$",
  },
];

function FontStyle() {
  const [image, setImage] = useState(null);
  const [value, setValueI] = useState(null);

  const [cValue, setCValue] = useState("");
  const [fontStyles, setFontStyles] = useState([]);
  const [action, setAction] = useState("view");
  const [rowData, setRowData] = useState(null);
  const [modal, setModal] = useState(false);

  const notificationCtx = useContext(NotificationContext);

  const slug = ltrim(window.location.pathname);
  const getPermissionData = hasPermission(slug);

  const { data: getData, refresh: refreshGetData } = useGet(
    FONT_STYLE_API[GET],
    {
      apiCall: "onload",
    }
  );
  const { refresh: delData } = useDelete(FONT_STYLE_API[DELETE]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [permissionData, setPermissionData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    setPermissionData(getPermissionData);
  }, []);

  useEffect(() => {
    //console.log(permissionData);
    if (!permissionData) {
      navigate("/dashboard", { replace: true });
    }
  }, [permissionData]);

  useEffect(() => {
    if (getData) {
      setFontStyles(getData.data || []);
    }
  }, [getData]);

  const columns = [
    { field: "name", headerName: "Name", width: 150, sortable: true },
    {
      field: "title",
      headerName: "Font Type",
      width: 150,
      sortable: true,
    },
    {
      field: "price",
      headerName: "Price",
      width: 150,
      sortable: true,
    },
    {
      field: "currency",
      headerName: "Currency",
      width: 150,
      sortable: true,
    },
    {
      field: "value",
      headerName: "Value",
      width: 200,
      sortable: true,
      renderCell: (params) => {
        const linkText = params.value || '';
        const textWithLineBreaks = insertLineBreaks(linkText, 25);
        return (
          <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
            {textWithLineBreaks}
          </div>
        );
      }
    },
    {
      field: "image",
      headerName: "Image",
      width: 200,
      renderCell: (params) => (
        <img
          src={appendImage(params.value)}
          style={{ transform: "scale(.8)" }}
        />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 100,
      renderCell: (params) => (
        <Box
          sx={{
            m: 1,
            position: "relative",
          }}
        >
          {permissionData.update == true && (
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => editRowData(params)}
            >
              <EditIcon />
            </IconButton>
          )}
          {permissionData.delete == true && (
            <IconButton
              aria-label="delete"
              color="outlined"
              onClick={() => deleteRowData(params)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ),
    },
  ];

  const getModalTitle = () => {
    if (action === "edit") {
      return "Update Font Style";
    } else if (action === "add") {
      return "Add Font Style";
    } else {
      return "Delete Font Style";
    }
  };

  const closeModal = () => {
    setModal(false);
  };

  const currencyHandleChange = (event) => {
    setCValue(event.target.value);
  };

  const editRowData = (params) => {
    const { name, title, price, currency, image, value } = params.row;
    console.log(image, "image");
    setRowData(params.row);
    setModal(true);
    setAction("edit");
    setCValue(currency);
    setValue("name", name);
    setValue("title", title);
    setValue("price", price);
    setImage(image);
    setValueI(value);
  };

  const onSubmit = async (data) => {
    console.log('data : ', data);
    if (action === "add") {
      const image = data.image[0];
      const value = data.font[0];
      const formData = new FormData();
      formData.append("image", image);
      formData.append("value", value);
      formData.append("price", data.price);
      formData.append("currency", cValue);
      formData.append("title", data.title);
      formData.append("name", data.name);
      axios({
        async: true,
        url: FONT_STYLE_API[POST],
        method: "POST",
        processData: false,
        contentType: false,
        mimeType: "multipart/form-data",
        data: formData,
      }).then((response) => {
        console.log(response, "response");
        closeModal();
        refreshGetData();
        notificationCtx.showNotification({
          title: response.data.message,
          status: "success",
        });
      });
    } else {
      const formData = new FormData();
      console.log(data, "data");
      if (typeof data.image === "object" && data.image.length > 0) {
        const image = data.image[0];
        formData.append("image", image);
      }
      if (typeof data.value === "object" && data.value.length > 0) {
        const value = data.value[0];
        formData.append("value", value);
      }
      formData.append("price", data.price);
      formData.append("currency", cValue);
      formData.append("title", data.title);
      formData.append("name", data.name);
      formData.append("_id", rowData._id);
      axios({
        async: true,
        url: FONT_STYLE_API[PUT],
        method: "PUT",
        processData: false,
        contentType: false,
        mimeType: "multipart/form-data",
        data: formData,
      }).then((response) => {
        console.log(response, "response");
        closeModal();
        refreshGetData();
        notificationCtx.showNotification({
          title: response.data.message,
          status: "success",
        });
      });
    }
  };

  const deleteRowData = (params) => {
    setRowData(params.row);
    setModal(true);
    setAction("delete");
  };

  const deleteHandler = () => {
    const data = {
      _id: rowData._id,
    };

    delData({ data }, { refreshGetData, closeModal });
  };

  return (
    <Grid container spacing={6} style={{ marginTop: "0" }}>
      <Grid item xs={12} style={{paddingTop: "0"}}>
        <Card>
          <CardHeader
            title="Font Styles"
            titleTypographyProps={{ variant: "h3", color: "primary.dark" }}
            action={
              permissionData.create == true && (
                <Button
                  variant="contained"
                  onClick={() => {
                    setModal(true);
                    setAction("add");
                  }}
                >
                  Add Font File
                </Button>
              )
            }
          />
          <div style={{ width: "100%" }}>
            <DataGrid
              getRowId={(row) => row._id}
              rows={fontStyles}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 20,
                  },
                },
              }}
              rowHeight={125}
              pageSizeOptions={[5]}
              onCellEditCommit={(params) => setRowId(params.id)}
            />
          </div>
        </Card>
      </Grid>
      {(permissionData.create == true ||
        permissionData.update == true ||
        permissionData.delete == true) && (
        <CustomizedDialogs
          showModal={modal}
          closeModal={closeModal}
          modalTitle={getModalTitle()}
        >
          {action === "delete" ? (
            <div>
              <p>Are you sure want to Delete?</p>
              <div
                className="align-right"
                style={{ display: "flex", justifyContent: "right" }}
              >
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  onClick={deleteHandler}
                >
                  Delete
                </Button>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Font Type"
                    placeholder="Enter Font Type"
                    {...register("title", { required: true })}
                  />
                  {errors.title && (
                    <span className="error-text">This field is required.</span>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Name"
                    placeholder="Enter Name"
                    {...register("name", { required: true })}
                  />
                  {errors.name && (
                    <span className="error-text">This field is required.</span>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="number"
                    fullWidth
                    label="Price"
                    placeholder="Enter Price"
                    {...register("price", { required: true })}
                  />
                  {errors.price && (
                    <span className="error-text">This field is required.</span>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="select-role">Select Currency</InputLabel>
                    <Select
                      labelId="select-currency"
                      id="select-currency"
                      value={cValue}
                      label="currency"
                      onChange={currencyHandleChange}
                    >
                      {currencyArr.map((currency) => (
                        <MenuItem key={currency.name} value={currency.name}>
                          {currency.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                  <Grid item xs={12}>
                    <Box display="inline-flex" alignItems="center">
                      <ImagePicker
                        {...{ register, errors }}
                        image={image}
                        value="image"
                      />
                      <Tooltip title="Image Resolution - 1200×1406 Pixels" placement="right">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Grid>
                <Grid item xs={12}>
                  <ImagePicker
                    {...{ register, errors }}
                    image={value}
                    value="font"
                  />
                </Grid>
                {/* <Grid item xs={12}>
                {!value && (
                  <FormControl>
                    <FormLabel>Value Upload</FormLabel>
                    <TextField
                      fullWidth
                      id="value-upload"
                      type="file"
                      {...register("value")}
                    />
                  </FormControl>
                )}

                {errors.value && (
                  <span className="error-text">This field is required</span>
                )}
                {value && (
                  <>
                    <div>
                      <p>{value}</p>
                    </div>
                    <FormLabel>Change Value Upload</FormLabel>
                    <TextField
                      fullWidth
                      id="value-upload"
                      type="file"
                      {...register("value")}
                      onChange={() => setValueI(null)}
                    />
                  </>
                )}
              </Grid> */}
                <Grid item xs={12}>
                  <div
                    className="align-right"
                    style={{ display: "flex", justifyContent: "right" }}
                  >
                    <Button type="submit" color="secondary" variant="contained">
                      Submit
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          )}
        </CustomizedDialogs>
      )}
    </Grid>
  );
}

export default FontStyle;
